// Generated by Framer (1cbc8a2)

import { addFonts, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["fv2iJeFOj"];

const serializationHash = "framer-z9kEQ"

const variantClassNames = {fv2iJeFOj: "framer-v-1y66y4d"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "fv2iJeFOj", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1y66y4d", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"fv2iJeFOj"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><Image background={{alt: "", fit: "fill", pixelHeight: 800, pixelWidth: 800, sizes: "16px", src: "https://framerusercontent.com/images/qBaq2Pok61msdnJ2jr3oa703g.png", srcSet: "https://framerusercontent.com/images/qBaq2Pok61msdnJ2jr3oa703g.png?scale-down-to=512 512w,https://framerusercontent.com/images/qBaq2Pok61msdnJ2jr3oa703g.png 800w"}} className={"framer-s5vtme"} data-framer-name={"Wonder logo"} layoutDependency={layoutDependency} layoutId={"ns3qfPvFP"} style={{transformPerspective: 1200}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-z9kEQ.framer-cjjd75, .framer-z9kEQ .framer-cjjd75 { display: block; }", ".framer-z9kEQ.framer-1y66y4d { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 16px; justify-content: center; overflow: visible; padding: 0px; position: relative; width: 16px; }", ".framer-z9kEQ .framer-s5vtme { flex: none; height: 16px; left: calc(50.00000000000002% - 16px / 2); overflow: hidden; position: absolute; top: calc(50.00000000000002% - 16px / 2); width: 16px; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-z9kEQ.framer-1y66y4d { gap: 0px; } .framer-z9kEQ.framer-1y66y4d > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-z9kEQ.framer-1y66y4d > :first-child { margin-left: 0px; } .framer-z9kEQ.framer-1y66y4d > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 16
 * @framerIntrinsicWidth 16
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerztxGPyepc: React.ComponentType<Props> = withCSS(Component, css, "framer-z9kEQ") as typeof Component;
export default FramerztxGPyepc;

FramerztxGPyepc.displayName = "Spinning logo";

FramerztxGPyepc.defaultProps = {height: 16, width: 16};

addFonts(FramerztxGPyepc, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})